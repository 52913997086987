<template>
    <div class="home view">
        <h1>VIEW OUR PRODUCTS THROUGH&nbsp;A&nbsp;DIFFERENT LENS</h1>
        <p>
            Welcome to the SCIEX product launch hub. Here, you can uncover a different perspective 
            on some of our latest products and innovations.
        </p>
        <p>
            By looking through an &lsquo;internal lens&rsquo;, you&rsquo;ll discover behind-the-scenes 
            stories and insights from your fellow colleagues, showcasing our collective achievement 
            in bringing each innovative product to life.
        </p>
        <p>
            Get started by selecting a product below or from the menu to uncover a new perspective.  
        </p>

        <div style="padding-right: 4rem;">
            <ul class="linkList">
                <li v-for="(product, index) in routes" :key="index" :class="product.class+'bg'">
                    <router-link @click="resetStoryValues" :to="product.path">
                    <div class="icon">
                        <img v-if="product.class == 'zenoTOF'" src="/imgs/BeExtraordinary-Logo-480x62.png" alt="">
                        <img v-if="product.class == 'biophase'" src="/imgs/TakeCharge-Logo-342x62.png" alt="">
                        <p v-if="product.class == 'oneomics'">One cloud. Countless opportunities.</p>                      
                    </div>
                    <h3>{{product.meta.productName}}</h3>
                    </router-link>
                </li>
            </ul>
        </div>
        <p>
            Do you have a story to share? Email <a href="mailto:communications@sciex.com">communications@sciex.com</a> today.
        </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  computed: {
      routes: function() {
          var e = this.$router.options.routes.filter((function(e) {
              if ("Home" != e.name)
                  return e
          }
          ));
          return e
      }
  },
  methods: {
      resetStoryValues() {
          this.$store.commit("resetLensPostition");
      }
  }
};
</script>


<style scoped>

.icon {
    display: flex;
    justify-content: left;
    min-height: 20px;
}
.icon p {
    padding: 0 !important;
    margin: 0 !important;
}
.icon img {
    max-height: 16px;
}
.home {
    padding:6.5rem 3rem 1.5rem 9rem;
    color:#fff;
}
.home.view {
    height: 100%;
    overflow: auto;
    width: 1083px;
}
h1 {
    letter-spacing:-.1rem;
    font-size:4rem;
    line-height:.9;
    margin-bottom:2rem;
    margin-left: -3px;
}
h2 {
    font-size:1rem;
    margin:2.5rem 0 .7rem 0
}
h3 {
    font-size:2.25rem;
    line-height: 1;
    padding-top: 3px;
}
.home p {
    padding-right:12rem
}
.home p a {
    color: inherit;
    font-weight: bold;
    letter-spacing: 0.1px;
}
.linkList {
    list-style:none;
    display:flex;
    flex-wrap: wrap;
    padding:0;
    margin:0;
    width:100%
}
.linkList li {
    width: calc(33.3% - 1rem);
    margin: 0 1rem 1rem 0;
    border-left:2px solid #fff;
    background-color:rgba(0,0,0,0.4);
}
.linkList li a {
    display:block;
    text-decoration:none;
    color:#fff;
    padding:1rem;
    /* margin:0 1rem 1rem 0; */
    min-width:33.3%;
    letter-spacing: -1px;
}
</style>