import { createStore } from "vuex";

export default createStore({
  state: {
    showNav: false,
    productVisual: {
        width: 750
    },
    viewingStory: "",
    mousePos: {
      x: 0,
      y: 0
    },
    lensPos: {
        x: 652,
        y: 521
    },
    displayVideo: {
        show: false,
        vid: ''
    },
    currentPg: 1,
    products: {
        biophase: {
            stories: {
                story01: {
                    sid: "story01",
                    pos: {
                        x: 136,
                        y: 368
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Mark Lies",
                        associate_title: "Senior Product Manager",
                        associate_function: "",
                        story: "Everything about BioPhase 8800 is customer driven. The development of this product directly responds to what we have been hearing from our customers over the years at trade shows, annual capillary electrophoresis (CE) events and more: they want a product with improved robustness that allows for faster analysis and creates greater efficiency in analytical workflows. We’re excited to have addressed a real need in the industry.",
                        img: "/imgs/peopleImgs/BioPhase-MarkLies.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 01",
                        story: "Designed for robustness, the system's hardware and software advances ensure repeatability and increased reliability.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story02: {
                    sid: "story02",
                    pos: {
                        x: 514,
                        y: 270
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Marcia Santos",
                        associate_title: "Staff Applications Scientist",
                        associate_function: "",
                        story: "During the development of BioPhase 8800, I worked in three different areas: applications development, Validation Lead, and Lead Customer (technical product manager). This experience gave me the unique opportunity to work in a multidisciplinary environment, not only across R&D and marketing but also across geographies, time zones and cultures. The biggest challenge was to find innovative and efficient ways to communicate and work effectively amid a pandemic. This project has taught me that we can overcome the most difficult times through perseverance, humility, and teamwork. This picture was taken at Area 51 lab in Brea, CA when Tingting Li and I ran the prototype ‘Hotel’ for the first time in October 2020.",
                        img: "/imgs/peopleImgs/BioPhase-MarciaSantos.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 02",
                        story: "The BioPhase 8800 system provides automated, quantitative and repeatable capillary electrophoresis sodium dodecyl sulfate (CE-SDS) separations, delivering high resolution purity analysis to help our customers keep pace with workloads while also minimizing sample prep and processing time.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story03: {
                    sid: "story03",
                    pos: {
                        x: 489,
                        y: 457
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Nirav Soni",
                        associate_title: "Manager, Quality Engineering",
                        associate_function: "",
                        story: "My experience in supporting the development of the BioPhase 8800 system has been incredible. I have been involved in determining critical to quality parts and maintaining requirements of the design controls. My work has provided an extra layer of stability to ensure the system consistently performs well, ultimately helping to provide a higher level of customer satisfaction. In short, I would say that BioPhase 8800 is a true example of a diverse team working in collaboration for a common goal.",
                        img: "/imgs/peopleImgs/BioPhase-NiravSoni.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 03",
                        story: "Flexible to our customers' workflow requirement, switching between UV and UV-LIF detection is simple and seamless. Integrated detection modules make it easy to go from one assay to the next, without sacrificing consistency or performance.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story04: {
                    sid: "story04",
                    pos: {
                        x: 363,
                        y: 114
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Sahana Mollah",
                        associate_title: "Senior Manager, Biologics Global Collaboration and CE Technical Marketing Coordinator",
                        associate_function: "",
                        story: "Customers are being faced with the challenge of analyzing molecules of increasing complexity. Meanwhile, the market also demands faster time-to-market and an increased speed of analysis. I’m excited that the BioPhase 8800 will help meet these needs for our customers. As a Technical Marketing Manager, I generate content that highlights workflows and features of this product, none of which would have been possible without the exemplary teamwork and coordination between cross functional teams including R&D, Applications and Marketing. I am confident that the BioPhase 8800 will drive increased market success for SCIEX.",
                        img: "/imgs/peopleImgs/BioPhase-SahanaMollah.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 04",
                        story: "The increased throughput and reproducibility of this system minimizes bottlenecks in the drug development pipeline, helping shorten time to market.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story05: {
                    sid: "story05",
                    pos: {
                        x: 190,
                        y: 303
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Rich Carson",
                        associate_title: "Sr. Technical Support Specialist",
                        associate_function: "",
                        story: "I am grateful to have had the opportunity to be involved in the earliest stages of development of this new capillary electrophoresis platform. As a global service representative, I provide the voice of the internal and external customer from a field service organization perspective. Working together with R&D and Manufacturing, we strive to develop the product to ensure ease of manufacturing, ease of service, alignment between manufacturing and service test procedures, and system reliability. I also work with Product Management and Applications in areas of customer support such as data analysis and compliance features. I’m excited to see how much customers love this new product.",
                        img: "/imgs/peopleImgs/BioPhase-RichardCarson.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 05",
                        story: "Scientists can now assess molecular liabilities more quickly, allowing rapid developability assessment to streamline the selection of candidates to be progressed into an effective therapy.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story06: {
                    sid: "story06",
                    pos: {
                        x: 462,
                        y: 356
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Ingrid Cruzado Park",
                        associate_title: "Staff Development Scientist",
                        associate_function: "",
                        story: "I worked on the development of a new capillary isoelectric focusing (cIEF) chemistry kit for BioPhase 8800, which utilizes chemical mobilization to generate cIEF separations with high resolution. It also introduces a new reagent called the Neutral Capillary Conditioning Solution to extend capillary cIEF run life while maintaining its separation performance. To prevent customers from placing reagents in the wrong location, I incorporated different colored bottle caps in the chemistry kits that match colors in the reagent plate map. On top of this, I tested all three software programs of the BioPhase 8800 system: the Method and Sequence Editor (used to create new methods and sequences and to generate the reagent plate maps), the BioPhase Running Software (used to operate the system through a touch screen) and Dino (new data analysis software which is simpler and easier to use than the previous software, 32 Karat).",
                        img: "/imgs/peopleImgs/BioPhase-IngridCruzadoPark.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 06",
                        story: "Our validated assay kits help simplify operation and minimize user error in core industry applications. Plus, our factory-built, multi-capillary, temperature-controlled cartridges help to ensure maximum data reproducibility.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story07: {
                    sid: "story07",
                    pos: {
                        x: 160,
                        y: 158
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Patty Pang",
                        associate_title: "Senior Project Manager",
                        associate_function: "",
                        story: "I’m very happy to have had the opportunity to manage the development of a brand new SCIEX CE instrument. It was an amazing experience working with engineers and scientists on innovative ideas to help our customers improve their workflow and productivity. The challenge has been managing a global team – with members from Southern California to Canada to Singapore as well as collaborators worldwide – during the global COVID pandemic. This challenge brought the team closer together and fully demonstrated the power of collaboration, hard work and tenacity. I feel very privileged to be part of the team.",
                        img: "/imgs/peopleImgs/BioPhase-PattyPang.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 07",
                        story: "The SCIEX BioPhase 8800 is a multi-capillary electrophoresis system that enables biopharma scientists to run multiple samples in parallel, accelerating the development and execution of sensitive, high-throughput analytical methods.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story08: {
                    sid: "story08",
                    pos: {
                        x: 525,
                        y: 146
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Brian Peterson",
                        associate_title: "Systems Engineer V-CE",
                        associate_function: "",
                        story: "Five years ago, Bedrock, now the BioPhase 8800, existed as a storyboard created from leanings after visiting several customer sites to see what capabilities and user-friendliness they were lacking. We strived to incorporate much of this in what would be the next generation CE instrument. As a member of the Hardware Engineering team, I developed the optical design of the instrument and worked on many other subsystems. The optical system is an example of innovation that used a novel approach to analyze 8 capillaries simultaneously using UVA or LIF, becoming the first subsystem to file a patent application. As the BioPhase 8800 has been released, many of the innovations envisioned in that early storyboard have become a reality. This engineering team has developed a completely new product from ground up and I am so fortunate to have been a part of it.",
                        img: "/imgs/peopleImgs/BioPhase-BrianPeterson.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 08",
                        story: "The SCIEX BioPhase 8800 system facilitates parallel processing of eight samples simultaneously, while retaining the capability to deliver sensitive CE-SDS, and CIEF analysis enabling uncompromised accuracy for large sample sets and faster time to answers.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story09: {
                    sid: "story09",
                    pos: {
                        x: 631,
                        y: 428
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Stephen Mele",
                        associate_title: "Director, Finance",
                        associate_function: "",
                        story: "Being involved in the development of a brand new platform for our current capillary electrophoresis (CE) instrument product line was both exciting and challenging. In a project of this magnitude, financial projections are constantly changing and my responsibility was to ensure that the BioPhase project fit into overall goals for both CE Biopharma and SCIEX. Monthly forecasts and yearly budgets were submitted in line with the current project landscape, while actual results were reported in line with the current accounting policy. Now that is has launched,, analysis will be ongoing as we monitor the expected increase in customer usage of this new CE Instrument.",
                        img: "/imgs/peopleImgs/BioPhase-StephenMele.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 09",
                        story: "",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story10: {
                    sid: "story10",
                    pos: {
                        x: 341,
                        y: 167
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Wai-Mun Yong",
                        associate_title: "Technical Manager, Product Compliance",
                        associate_function: "",
                        story: "As a Principal Engineer in Product Compliance, my job was to ensure our BioPhase 8800 product met all regulatory standards in the countries it was being sold in. This included working with external agencies to verify consumer safety regulations, testing Electromagnetic Compatibility (EMC) and using environmental-friendly solutions to meet Restriction of Hazardous Substances (RoHS) requirements. As you can imagine, this responsibility became increasingly difficult with the spread of COVID-19. Rather than travelling to our SCIEX office in Brea, California to complete the testing, I had to rely heavily on video meetings and work closely with our partners at Beckman who reside at the same site. Being flexible, developing strong partnerships and adapting quickly were crucial in adjusting my plan to meet our regulatory requirements. I’m proud to see the finished product get into the hands of our customers around the world!",
                        img: "/imgs/peopleImgs/BioPhase-Wai-MunYong.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 10",
                        story: "",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story11: {
                    sid: "story11",
                    pos: {
                        x: 223,
                        y: 398
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Vi Leong Ng",
                        associate_title: "Project Manager II",
                        associate_function: "CTO",
                        story: "BioPhase 8800 is a brand new, complicated design with an extremely high number of new parts. As the Transfer Project Manager, the first key milestone we needed to achieve with the team in Singapore was to complete the pilot build. It’s not easy to get full materials ready to build and test from scratch and there were a lot of challenges of the design transfer due to travel restrictions because of COVID-19. I can still remember how excited the team was when the first unit was initialized successfully after step-by-step troubleshooting via video conferencing. Without the Singapore & Brea cross-functional teams’ (R&D, MAE, ICS, PPS, VS, SCM, QA) commitment, hard work, and countless late nights and early mornings, we wouldn’t have been able to cross all of the key milestones.",
                        img: "/imgs/peopleImgs/BioPhase-ViLeongNg.jpg",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 11",
                        story: "",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story12: {
                    sid: "story12",
                    pos: {
                        x: 253,
                        y: 268
                    },
                    type: "extonly", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "",
                        associate_title: "",
                        associate_function: "",
                        story: "",
                        img: "",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "BONUS",
                        title: "External Story 09",
                        story: "The BioPhase 8800 system's reimagined software makes it faster and easier for our customers to get results. Simple drag-and-drop functionality for method and sequence creation complements innovative data analysis, to accelerate characterization from start to finish.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story13: {
                    sid: "story13",
                    pos: {
                        x: 450,
                        y: 156
                    },
                    type: "extonly", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "",
                        associate_title: "",
                        associate_function: "",
                        story: "",
                        img: "",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "BONUS",
                        title: "External Story 10",
                        story: "The system's convenient 96-well plate format is compatible with standard liquid handling systems.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story14: {
                    sid: "story14",
                    pos: {
                        x: 395,
                        y: 324
                    },
                    type: "extonly", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "",
                        associate_title: "",
                        associate_function: "",
                        story: "",
                        img: "",
                        vid: "",
                
                    },
                    ex: {
                        bonus: "BONUS",
                        title: "External Story 11",
                        story: "Innovated from the ground up, the SCIEX BioPhase 8800 helps biopharmaceutical labs take charge of their development pipelines.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                }

            }
        },
        zenotof7600: {
            stories: {
                story01: {
                    sid: "story01",
                    pos: {
                        x: 151,
                        y: 436
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Igor Chernushevich",
                        associate_title: "Senior Staff Research Scientist",
                        associate_function: "LCMS R&amp;D",
                        story: "The name of the product, ZenoTOF, stems from the original name Igor Chernushevich proposed for an approach to improve the duty cycle in SCIEX Time-of-Flight (TOF) instruments. “The situation that causes light ions to chase the heavy ions reminded me of the ancient paradox of Achilles and the Tortoise devised by Greek philosopher Zeno of Elea,” says Igor. “According to Zeno, Achilles, who is in a footrace with the tortoise, will never catch up with it if the tortoise has been given a head start.” Although a study he co-authored in 2009 proves the opposite for ions, this story was the inspiration behind the new Zeno trap.",
                        img: "/imgs/peopleImgs/ZenoTOF-Igor_Chernushevich.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 01",
                        story: "This high-resolution accurate mass system combines the power of Zeno trap pulsing with EAD fragmentation technology (electron activated dissociation) to unlock sensitivity gains that reveal new, rare, or even previously undetected information on an everyday basis.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story02: {
                    sid: "story02",
                    pos: {
                        x: 432,
                        y: 334
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Takashi Baba",
                        associate_title: "Senior Staff Research Scientist",
                        associate_function: "LCMS R&amp;D",
                        story: "“My work in invention, instrumentation and application research, which took 10 years to finalize, helped improve the sensitivity and reaction speed of Electron activated dissociation (EAD) to be compatible to LC-MSMS. The EAD cell replaces the need for multiple different instruments to cover the same range of analysis methods. We developed this breakthrough method to analyze various molecules, including lipids, so that customers can use it in their work to solve biochemical problems.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Takashi_Baba.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 02",
                        story: "Electron activated dissociation (EAD) allows for a range of reagent free electron-based fragmentation mechanisms within one device, and has the capability to fragment peptides whilst retaining critical MS/MS information for both identification and localisation of PTMs. Unlike other electron-based fragmentation techniques, EAD delivers reproducible, consistent data, even at fast scan speeds, compatible with UHPLC timeframes, delivering higher efficiency than ETD.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                
                story03: {
                    sid: "story03",
                    pos: {
                        x: 552,
                        y: 390
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Bob Haufler",
                        associate_title: "Staff Research Scientist",
                        associate_function: "R&amp;D",
                        story: "Watch the video",
                        img: "/video/ZenoTOF-Bob_Video-FINAL.jpg",
                        vid: "/video/ZenoTOF-Bob_Video-FINAL.mp4",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 03",
                        story: "The Zeno trap technology overcomes QTOF MS/MS duty cycle deficiencies, ensuring > 90% of all ions injected into the TOF.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story04: {
                    sid: "story04",
                    pos: {
                        x: 231,
                        y: 359
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: ["Jean-Baptiste Vincendet", "Olivier Hutinel"], 
                        associate_title: ["Sr. Market Development Manager, Life Science Research &amp; Clinical", "Sr. Sales Representative, MS France"],
                        associate_function: "Market Development & Sales",
                        story: "“As part of the sales team, we always look forward to presenting new technological advancements to our customers. We were thrilled to manage the very first sale of the ZenoTOF 7600 system globally at the end of Q1 2021! For this particular deal, we had the feeling that all the stars were aligned at the right time: a great customer relationship for many years, a ground-breaking product, and available budget for it. At the end of Q3 2020, it was very early days to present the ZenoTOF 7600, but we decided to move away from 6600+ and that was the right strategy versus the competition. Product Management, specifically that of Jose Castro-Perez and Erika Lin, was of great help in moving around tender and quoting processes. We have the feeling that we now have the right solution to differentiate from competition and be back in high-resolution mass spectrometry, especially in the Omics market.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Jean_Baptiste-Olivier.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 04",
                        story: "The first global sale of the ZenoTOF 7600 system was to Professor Jérôme Lemoine at the Institute of Analytical Sciences, Lyon University.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story05: {
                    sid: "story05",
                    pos: {
                        x: 538,
                        y: 276
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Graham Leith",
                        associate_title: "Chief Engineer, Hardware",
                        associate_function: "Hardware",
                        story: "“For the ZenoToF 7600 system, the detection team created an innovative design for the detector and signal handling electronics to ensure the customer would get the best possible dynamic range and resolution out of the system, while also making it easier to assemble and service the detector.  We faced several difficult technical challenges along the way, but with perseverance, collaboration and support of the ZenoTOF 7600 project team, we were able to overcome these challenges to deliver the product on time.  It was a great experience to work alongside such a talented group of people on this project as we nurtured the new technologies from the early stages of capability development to product launch.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Graham_Leith.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 05",
                        story: "The ZenoTOF 7600 system enables >5 orders of inter-scan linear dynamic range for quantitation.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story06: {
                    sid: "story06",
                    pos: {
                        x: 376,
                        y: 323
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Zoe Zhang",
                        associate_title: "Manager, Biopharma Applications",
                        associate_function: "Biopharma",
                        story: "“My role is to validate the application of our ZenoTOF 7600 system in Biopharma. I have presented our data from ZenoTOF 7600 to more than 20 big Biopharma customers, all of whom were incredibly impressed by the power of this instrument and its data quality. It is the first time we received so many compliments for our HRMS system and we feel we can really have our ground in the Biopharma market. This instrument has the capability to resolve many pain points that our Biopharma customers experience. This is thanks to its ability to provide isomer differentiation, localize glycan occupancy, achieve high confidence for complex disulfide peptides, and more.  It took us a lot of effort to achieve the results we see today, but it’s amazing to see the final product come to life.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Zoe_Zhang.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 06",
                        story: "Unique Zeno trap pulsing provides significant gains in peptide and protein identifications for proteomics experiments. In fact, up to ~ 45% improvements in the number of protein IDs and up to a 145% increase in the number of peptide IDs, compared to previous TripleTOF technologies.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                
                
                story07: {
                    sid: "story07",
                    pos: {
                        x: 231,
                        y: 256
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Michael Johnson",
                        associate_title: "Staff Specialist, Technical Training",
                        associate_function: "Service",
                        story: "“The unprecedented times of the COVID-19 pandemic took the world by surprise and forced nearly everyone to restructure the way they live and do business. The Commercial and Service Training group at SCIEX was no exception. Service Training has always relied on in-person training and hands-on training as there is no substitute for using the operating software to acquire and interpret data or holding an Ion Optics assembly, a Mechanical Device or an Electronic Module in your hands. Only in-person can you really see and feel how it works, how it is removed, how it is serviced and how it is re-installed within a SCIEX Mass Spectrometer or a Liquid Chromatography System from another company.<br/><br/>The Service Training Team worked relentlessly to build virtual training materials for the new training environment which includes high resolution cameras, noise cancelling headsets, tripods, very large monitors and video multiplexers that are capable of quickly switching between video signals.<br/><br/>Remote Desktop Connection, Bomgar and Microsoft Teams are being used to allow trainees to perform installation tuning and testing procedures, establish communication between the LC System and the Mass Spectrometer, and build data acquisition methods as well as acquire and interpret data. The ZenoTOF 7600 is the latest SCIEX product to be introduced in a virtual training world. Service Training is meeting the challenge with trainers from Darmstadt, Framingham and Shanghai for possibly the most complex mass spectrometer that SCIEX has ever made.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Michael_Johnson.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 07",
                        story: "Customers can maximize their productivity, increase their uptime and accelerate the quality of their results with the SCIEX Now™ Support Network – our team that delivers all the support of their lab needs.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                
                story08: {
                    sid: "story08",
                    pos: {
                        x: 495,
                        y: 246
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Tom Knapman",
                        associate_title: "Director, Strategic Marketing, Brand & Creative",
                        associate_function: "Marketing",
                        story: "“<em>Be Extraordinary</em> is more than just the tagline for the ZenoTOF 7600 system. It is representative of the new brand attitudes we have developed that focus on listening to all possible viewpoints, as well as championing uniqueness and diverse, free thinking.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Tom_Knapman.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 08",
                        story: "Be Extraordinary: Customers can take their research to extraordinary new levels enabling breakthrough science, fresh perspectives and discoveries previously unimagined with the SCIEX ZenoTOF 7600 system.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },

                
                story09: {
                    sid: "story09",
                    pos: {
                        x: 439,
                        y: 395
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Bill Loyd",
                        associate_title: "Project Scientist",
                        associate_function: "R&amp;D",
                        story: "Watch the video",
                        img: "/video/ZenoTOF-Bill_Video-FINAL.jpg",
                        vid: "/video/ZenoTOF-Bill_Video-FINAL.mp4",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 09",
                        story: "Customers can detect up to 20x more ions in every experiment and access a spectrum of tunable fragmentation techniques to uncover new perspectives for every molecule, in every experiment.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                
                story10: {
                    sid: "story10",
                    pos: {
                        x: 400,
                        y: 256
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Michael Driscoll",
                        associate_title: "Senior Territory Manager, Sales",
                        associate_function: "Sales",
                        story: "“Sales is always a mix of timing, product and people. Understanding each of these factors led us to the first sale of a ZenoTOF 7600 system in North America. A year after our customer started with one 6500+ QTRAP, they found themselves needing to rapidly build out their lab and address their Met ID, characterization and large molecule quantification needs. We secured three 6500+ QTRAPS but were scrambling when the 6600 TTOF faced competition versus a Thermo Exploris. With help from product management, Jose Castro-Perez, Western Sales Management, David Monk, and team member Paul Clemens in late Q4 2020, Loren Olsen, Market Development Specialist, and I were able to pivot to the ZenoTOF 7600 using approved white papers and NDA cleared VIP info. The ZenoTOF’s game changing versatility with the EAD, Zeno Trap, and qualitative/quantitative capabilities fit the customer’s current and future needs perfectly. What cemented this four-system sale was the continuous attention to the customer’s ever-changing needs so we could grow the relationship and build upon SCIEX’s strong brand in the Triple Quadrupole (QQQ) market.  The ZenoTOF 7600 fills many gaps in our high-resolution offering in multiple markets and, in many cases, puts us in a leading position!”",
                        img: "/imgs/peopleImgs/ZenoTOF-Michael_Driscoll.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 10",
                        story: "The first sale of the ZenoTOF 7600 in North America was to Exelixis, an oncology-focused biotechnology company that strives to accelerate the discovery, development and commercialization of new medicines for difficult-to-treat cancers.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story11: {
                    sid: "story11",
                    pos: {
                        x: 582,
                        y: 442
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Sameer Kothari",
                        associate_title: "Senior Product Development Scientist",
                        associate_function: "Hardware",
                        story: "“With the ease of using the Optiflow Ion Source, we enabled our customers access to enhanced performance in Micro flow and Nano flow regimes. The Optiflow interface for Nano flow allows switching from high flow to Nano flow without venting the instrument.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Sameer_Kothari.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 11",
                        story: "The ZenoTOF 7600 system is compatible with advanced source designs that minimize contamination and allows our customers to obtain fast, automated calibrations across flow rate ranges.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                


                // Hidden Stories

                story12: {
                    sid: "story12",
                    pos: {
                        x: 510,
                        y: 210
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Michelle Watson",
                        associate_title: "FP&A Manager",
                        associate_function: "Finance",
                        story: "“In 2019, our LCMS R&amp;D team was developing three platform projects at the same time: ZenoTOF 7600, Triple Quad 7500 and Echo MS. Since ZenoTOF 7600 and Triple Quad 7500 were procuring parts for prototype builds, and Echo MS was doing the same for Early Customer Engagement and pilot units, this created an additional layer of volatility in R&amp;D spending. My role in Finance was to ensure the right amount of budget was secured to deliver these projects and meet the strategic and financial objectives of the business. Although the situation was everchanging and complex, I enjoyed putting all the puzzle pieces together to help set us up for success.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Michelle_Watson.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 12",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story13: {
                    sid: "story13",
                    pos: {
                        x: 345,
                        y: 246
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Tim Mazejka",
                        associate_title: "Senior Manager, Financial Planning & Analysis",
                        associate_function: "Finance",
                        story: "“Over the past two years, I’ve had the exciting opportunity to build strong business partnerships in support of the launch of our ZenoTOF 7600 system. Within Finance, I had to come up with innovative ways to handle the treatment for Early Customer Evaluations (ECE), which involved enhancing our policies and developing a new decision matrix to provide guidelines to both product and project management teams. I also helped secure capital funding for 11 ZenoTOF 7600 systems to perform pre and post launch activities. By working with my Finance partners in EMEA, JAPAC and the Americas, collaboration has truly been at the heart of my work on this project. Our financial and reporting systems are now ready to track the success of this new and innovative product.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Tim_Mazejka.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 13",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story14: {
                    sid: "story14",
                    pos: {
                        x: 406,
                        y: 466
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Mark Cafazzo",
                        associate_title: "Sr. Director, Innovation",
                        associate_function: "CTO",
                        story: "“In my role I’m lucky to see each of our new products as they evolve from initial concept to a complete product ready for shipment to our customers. I work with the Product Managers, marketing specialists and technical experts in R&amp;D to ensure we understand the market problems the product will address and the value-adding features and benefits our technologies will provide. This way we can estimate the value of the product to our business long before we ramp up the development project. For ZenoTOF, my planning files date back to 2015. It has been fascinating to see the decisions and scope changes we’ve made as technologies advanced in R&amp;D to ultimately produce the amazing product we’ve launched!”",
                        img: "/imgs/peopleImgs/ZenoTOF-Mark_Cafazzo.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 14",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story15: {
                    sid: "story15",
                    pos: {
                        x: 153,
                        y: 333
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Tim Smith",
                        associate_title: "Sr. Manager, Americas Commercial Training",
                        associate_function: "Sales",
                        story: "“The launch of the ZenoTOF 7600 will put SCIEX at the forefront of the Accurate Mass market space with its innovative and game changing technologies. Preparing the salesforce for the launch of this product required six months of rigorous planning with global teams. Over a three-day period, the Commercial Training team worked in tandem with members of Product Management, Marketing and Support to deliver 35 training hours on product, applications highlights and competitor positioning to over 300 associates spanning three sales regions. The work required to execute the training program was enormous, but we are proud of the result and thrilled it had a positive impact on the organization.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Tim_Smith.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 15",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story16: {
                    sid: "story16",
                    pos: {
                        x: 271,
                        y: 368
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Neil Walsh",
                        associate_title: "Sr. Manager, Global Strategic Marketing – Accurate Mass, Pharma &amp; Life Science Research",
                        associate_function: "Marketing",
                        story: "“I have been involved with the ZenoTOF 7600 system from the very beginning, but especially since November 2020 when I took on additional leadership responsibilities in Strategic Marketing for our accurate mass portfolio. It’s been a pleasure working alongside our product management team, GSM team, regional teams and the Biopharma business unit to help deliver a truly differentiated accurate mass product to market. When it comes to product launches, I get the most excited about 8-10 weeks out from launch day because that’s when we’re in the final leg of the race. It’s when our team really starts to see the fruits of our labor. All the visuals and launch plans get firmed up and we begin to showcase our launch content externally and receive reactions from customers.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Neil_Walsh.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 16",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story17: {
                    sid: "story17",
                    pos: {
                        x: 422,
                        y: 196
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Chet Shah",
                        associate_title: "Quality Engineering, Sr. Specialist",
                        associate_function: "QARA",
                        story: "Watch the video",
                        img: "/video/ZenoTOF-Chet_Video-FINAL.jpg",
                        vid: "/video/ZenoTOF-Chet_Video-FINAL.mp4",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 17",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story18: {
                    sid: "story18",
                    pos: {
                        x: 576,
                        y: 318
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Ammar Ahmed",
                        associate_title: "Product Compliance Engineer",
                        associate_function: "QARA",
                        story: "Watch the video",
                        img: "/video/ZenoTOF-Ammar_Video-FINAL.jpg",
                        vid: "/video/ZenoTOF-Ammar_Video-FINAL.mp4",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 18",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story19: {
                    sid: "story19",
                    pos: {
                        x: 228,
                        y: 456
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Darren Soh",
                        associate_title: "Project Manager, Operation",
                        associate_function: "Operations",
                        story: "“As the Operations Project Manager, it’s been a privilege to work alongside cross-functional teams from R&D, MAE, ICS, PPS, VS, Planning, QA and Service. To help ensure a smooth execution of the new project (QTOF 7600) within the stipulated timeline, the team had to tackle tight schedules, overlapping phases and various technical challenges – such as assembly and testing issues – along the course of development and builds. Despite all the challenges and constraints caused by COVID-19, we were able to meet all the key milestones and hit the launch date successfully with the team’s support and synergy.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Darren_Soh.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 19",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story20: {
                    sid: "story20",
                    pos: {
                        x: 493,
                        y: 47
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Christie Hunter",
                        associate_title: "Director of Applications/Technical marketing",
                        associate_function: "Marketing",
                        story: "“I think I can speak for the whole technical marketing team when I say that we are very excited about the new ZenoTOF 7600 system. In our job role, we are responsible for generating all the scientific proof statements that will be used during product launch and during the entire product life cycle.  It definitely makes our job easier when we have a product that is extremely powerful and well suited to meet the application needs of our target customers. The breadth of technical stories that we will have for this new launch is outstanding and this, alongside the exciting marketing campaigns planned, will definitely generate a lot of excitement out there in the marketplace!”",
                        img: "/imgs/peopleImgs/ZenoTOF-Christie_Hunter.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 20",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story21: {
                    sid: "story21",
                    pos: {
                        x: 287,
                        y: 253
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Brien Byers",
                        associate_title: "Manager, Territory Specialists - Americas",
                        associate_function: "Sales & Service",
                        story: "“The Americas Territory Specialist group has performed the first pilot installs in the Framingham and Redwood City demo labs. Our associates also did two installs at Intabio in Fremont, California where they coupled the Blaze microchip system using imaged capillary isoelectric focusing (iCIEF) for sample separation, with the ZenoTOF 7600 to create a truly powerful analytical system. These first installs give the group valuable experience for mentoring Field Service Engineers at future customer installs and product knowledge that will help support maximum customer uptime.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Brien_Byers.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 21",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story22: {
                    sid: "story22",
                    pos: {
                        x: 150,
                        y: 388
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Scott Hamilton",
                        associate_title: "Project Manager, Hardware Development",
                        associate_function: "PMO",
                        story: "“I have been involved with the ZenoTOF 7600 system project since it was in the capability development – or research –  phase. I love that I get the opportunity to see the technology develop from a concept coming out of our research team and turn it into a product that is going to deliver new and improved ways for our customers to perform their work. I get the opportunity to work with many different functions throughout the project and it’s amazing to see the collaboration required to deliver a product like the ZenoTOF 7600. I can’t wait to see the success it achieves in the market!”",
                        img: "/imgs/peopleImgs/ZenoTOF-Scott_Hamilton.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 22",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story23: {
                    sid: "story23",
                    pos: {
                        x: 324,
                        y: 290
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Doug Simmons",
                        associate_title: "Technical Manager, Product Applications (Mass Spec R&amp;D)",
                        associate_function: "R&amp;D",
                        story: "“My team of Application Scientists at SCIEX Concord is responsible for ensuring that the ZenoTOF 7600 system performs up to our expectations and will meet the needs of our users. Back in 2017, we began working closely with Research on the new technology elements like Zeno, Electron activated dissociation (EAD), SCIEX OS and others in order to bring them to a level of maturity (the technologies, not the research scientists!) needed to bring value to the customer. This ongoing evaluation, development, and iteration effort continued throughout the project until the final stages where the team validated the performance of complete pilot instruments using customer workflows. Because it is our job to find problems, it often leaves us at the end of a project seeing only the imperfections. However, it is important to step back and realize what an achievement the 7600 system is. This is a product we can be proud of, and it is especially gratifying to see how enthusiastic our market-facing teams are about the system.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Doug_Simmons.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 23",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story24: {
                    sid: "story24",
                    pos: {
                        x: 420,
                        y: 137
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Elliott Jones",
                        associate_title: "Senior Director, Applied Biologics Workflow Development",
                        associate_function: "CE",
                        story: "“Supporting the ZenoTOF 7600 project for the last three years has been a milestone in my career. The EAD technology developed by Takashi Baba and the Toronto team, which expanded as an application by my group, is poised to change the nature of biopharma LCMS characterization. It is inspiring to see our customers grasp the potential of this ground-breaking innovation.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Elliott_Jones.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 24",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story25: {
                    sid: "story25",
                    pos: {
                        x: 391,
                        y: 344
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Pavel Ryumin",
                        associate_title: "Associate Staff Scientist",
                        associate_function: "R&amp;D",
                        story: "“Throughout working on the ZenoToF 7600 project, the most fascinating time for me was during the early days of commercialization of Electron-capture dissociation (ECD). For a long time, it was developed almost exclusively by Takashi Baba and was initially included in the ZenoTOF 7600 project as a research pet project. It was too complex and too novel, so it was embraced only by a few in the beginning. Initially, that put us in an underdog position which, in this case, truly united the core team. We were privileged to work with the best engineers: mechanical, electrical, manufacturing, firmware and software – everyone stepped up. Together we carried it to the first ECE in late 2018, where, in tandem with ZenoTOF 7600, we got very promising results. That finally convinced the business, and the many more people who contributed afterwards, to get it to the finish line. It was a pleasure to work on such technology and I am really looking forward to seeing its commercial success.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Pavel_Ryumin.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 25",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story26: {
                    sid: "story26",
                    pos: {
                        x: 145,
                        y: 261
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Jason Causon",
                        associate_title: "Technical Product Manager, Accurate Mass",
                        associate_function: "LCMS",
                        story: "Watch the video",
                        img: "/video/ZenoTOF-Jason_Video-FINAL.jpg",
                        vid: "/video/ZenoTOF-Jason_Video-FINAL.mp4",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 26",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },

                story27: {
                    sid: "story27",
                    pos: {
                        x: 496,
                        y: 303
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Sue Monahan",
                        associate_title: "Manager, Global EHS Governance",
                        associate_function: "EHS",
                        story: "“The ZenoTOF 7600 system is our first product with an embedded magnet system which presented unique EHS challenges. Consideration for product safety, manufacturing, and field service began during the early design concept phase in 2018. Because this was new technology, it was necessary for the team to investigate potential health and safety concerns related to magnetic components and develop design strategies to ensure that every safety requirement was being met. It was a privilege to work with our amazing engineering team on every step of the product development cycle and the transition to manufacturing.  Potential safety issues were carefully evaluated and the team rolled-up their sleeves to problem solve and create safety features in our product design. New training materials and work procedures were developed for the Singapore team and this information was leveraged for Field Service. As the project moved forward, I worked directly with the Field Service Support team and provided guidance on tool design, service procedures, parts returns and training. Ensuring that safety was incorporated into every element of our product development and launch process was both challenging at times and extremely rewarding. Congratulations team!”",
                        img: "/imgs/peopleImgs/ZenoTOF-Sue_Monahan.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 27",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story28: {
                    sid: "story28",
                    pos: {
                        x: 265,
                        y: 310
                    },
                    type: "hidden", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Nick Albeanu",
                        associate_title: "Senior Embedded Systems Designer",
                        associate_function: "Electrical",
                        story: "“From the early research work on the instrument controls for ZenoTOF it seemed compelling to expand the recently developed control platform using FIP Link, the proprietary mass spec protocol for module-to-module communication and hard real time synchronous operation of the analog modules. To achieve a uniform and consistent integration of controls for the two new research capabilities, Zeno and Electron activated dissociation (EAD), it was not enough to enable the existing real time capabilities in the initial version of the platform. This meant new work was needed to create new scan type devices and associated methods of synchronous control. At the same time, a new processing node was adopted for the FPGA/programmable logic devices, allowing recent technological advances in the field to become available to the new design. The result of this effort, together with the amazing work done by my colleagues in embedded Linux, is the ZenoTOF 7600 embedded controls, undoubtedly a top mass spec real time platform in the industry, highly optimized for performance, cost, scalability and flexibility, just to name a few of its key attributes.”",
                        img: "/imgs/peopleImgs/ZenoTOF-Nick_Albeanu.jpg",
                        vid: "",

                    },
                    ex: {
                        bonus: "",
                        title: "External Story 28",
                        story: ""
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },


            }
        },

        oneomics: {
            stories: {
                story01: {
                    sid: "story01",
                    pos: {
                        x: 358,
                        y: 164
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Matt Huebsch",
                        associate_title: "Senior Software Verification Specialist",
                        associate_function: "Software",
                        story: "“As a geographically distributed and diverse project team, we were able to rise to the challenges of delivering the next commercialized version of OneOmics in 2020, despite the many obstacles we've all faced with COVID-19. Our journey on building this product has been very enriching but couldn't have been possible without the collaboration and support of many talented scientists, product managers, and other stakeholders! With our newly adopted philosophy of continuous delivery, we are proud to have deployed our first 2021 release of OneOmics to support the ZenoTOF 7600 system and we are excited to be able to rapidly provide new functionality in OneOmics to our customers in the future. As the software product owner, I am excited to focus on our customers' scientific problems and to be able to contribute to the next big innovations in life science research.”",
                        img: "/imgs/peopleImgs/OneOmics-Matt_Huebsch.jpg",
                        vid: "",
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 01",
                        story: "Within Experiment Manager, customers can assemble and define the meta data in their study by building experiments from uploaded data, sharing projects such as data and results, and assigning study-level meta data.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story02: {
                    sid: "story02",
                    pos: {
                        x: 480,
                        y: 223
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Mark Cafazzo",
                        associate_title: "Sr. Director, Innovation",
                        associate_function: "CTO",
                        story: "Watch the video",
                        img: "/video/OneOmics-Mark_Video-FINAL.jpg",
                        vid: "/video/OneOmics-Mark_Video-FINAL.mp4",
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 02",
                        story: "With the power of cloud computing at their fingertips, customers can extract and visualize mass spectrometry-generated big data from proteomics, metabolomics and genomics projects on a single platform. They can turn this data into biological insights quickly and easily share those results with their collaborators.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story03: {
                    sid: "story03",
                    pos: {
                        x: 470,
                        y: 360
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Alex Antonoplis",
                        associate_title: "Staff Scientist, Proteomics",
                        associate_function: "Marketing",
                        story: "”As a member of the technical marketing team, I helped generate scientific content for the launch of OneOmics, such as application notes and training videos called Cloud Talks, which are posted in our SCIEX Community site. It was amazing to work with the project team in creating this content and I learned so much about the power of the platform in putting together these materials. We also use OneOmics all the time in our workflows and the ability to process data in the cloud allows for tremendous flexibility, and the results dashboards provided are very clear and really help to guide next steps in the lab. I look forward to more research projects with OneOmics and continuing to develop scientific content for the platform!”",
                        img: "/imgs/peopleImgs/OneOmics-Alex_Antonoplis.jpg",
                        vid: "",
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 03",
                        story: "Whatever our customers’ research demands, there is a OneOmics subscription package optimized for them. Since OneOmics is cloud-based, customers are no longer tethered to the instrument control terminal. Data can be processed whenever and wherever, as long as they have an internet connection.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story04: {
                    sid: "story04",
                    pos: {
                        x: 349,
                        y: 293
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Kranthi Chebrolu",
                        associate_title: "Staff Scientist",
                        associate_function: "",
                        story: "“In the future, metabolomics is moving towards adapting to SWATH/DIA technology which would revolutionize biomarker discovery process using small molecules. The Zeno SWATH technology would improve the detection and identification biomarkers. And the rich data acquired through ZenoTOF 7600 can be only processed through OneOmics. OneOmics provides various solutions for data processing and data visualization. It also provides solutions to detect metabolites with false discovery rates and filter them from the pool of potential biomarkers.”",
                        img: "/imgs/peopleImgs/OneOmics-Kranthi_Chebrolu.jpg",
                        vid: "",
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 04",
                        story: "The Proteomics workspace contains several applications to further our customers’ research and processing of their SWATH® Acquisition data.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story05: {
                    sid: "story05",
                    pos: {
                        x: 438,
                        y: 292
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Christie Hunter",
                        associate_title: "Director of Applications/Technical marketing",
                        associate_function: "Marketing",
                        story: "“The OneOmics project kicked off in 2013 as a collaboration with Illumina, one of the leaders in genomics, as an effort to increase the visibility of quantitative proteomics to the broader research community. It was our first foray into cloud processing and there were lots of great learnings through that collaboration. We have been evolving and expanding the platform, in collaboration with our power users over the last few years, to get us to the true data to biological answer pipeline we have today. Congratulations to the team for their incredible work in this cloud solution over the years – we finally made it across the commercial finish line! The OneOmics Suite in the cloud in combination with our new ZenoTOF 7600 system will be the 1-2 punch for Omics researchers.”",
                        img: "/imgs/peopleImgs/OneOmics-Christie_Hunter.jpg",
                        vid: "",
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 05",
                        story: "In the Metabolomics workspace, customers can extract key areas of their acquired data, as well as normalize and compute fold change (FC) and confidences. They can also assess the MS data quality and visualize changes across a study.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story06: {
                    sid: "story06",
                    pos: {
                        x: 504,
                        y: 356
                    },
                    type: "", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "Rajesh Bishundeo",
                        associate_title: "Cloud Vertical Software Development Manager",
                        associate_function: "Engineering",
                        story: "Watch the video",
                        img: "/video/OneOmics-Rajesh_Video-FINAL.jpg",
                        vid: "/video/OneOmics-Rajesh_Video-FINAL.mp4",
                    },
                    ex: {
                        bonus: "",
                        title: "External Story 06",
                        story: "OneOmics delivers quantitative and qualitative data tools for data processing, results visualization, pathway analysis and biological context.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story07: {
                    sid: "story07",
                    pos: {
                        x: 358,
                        y: 228
                    },
                    type: "extonly", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "",
                        associate_title: "",
                        associate_function: "",
                        story: "",
                        img: "",
                        vid: "",
                    },
                    ex: {
                        bonus: "BONUS",
                        title: "External Story 07",
                        story: "Our ProteinPilot software enables customers to process data dependent acquisition (DDA) data, as well as conduct database searches using the Paragon™ and Pro Group™ algorithms. It also helps them perform protein identification and build out their ion library.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story08: {
                    sid: "story08",
                    pos: {
                        x: 487,
                        y: 291
                    },
                    type: "extonly", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "",
                        associate_title: "",
                        associate_function: "",
                        story: "",
                        img: "",
                        vid: "",
                    },
                    ex: {
                        bonus: "BONUS",
                        title: "External Story 08",
                        story: "The iTRAQ workspace enables our customers to interrogate data from labelled quantification with the multiplexed, amine-specific, stable-isotope iTRAQ Reagent kits.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                story09: {
                    sid: "story09",
                    pos: {
                        x: 364,
                        y: 356
                    },
                    type: "extonly", // '' = internal & External, 'hidden' = Internal Only, 'extonly' = External Only
                    int: {
                        title: "Internal Story",
                        associate: "",
                        associate_title: "",
                        associate_function: "",
                        story: "",
                        img: "",
                        vid: "",
                    },
                    ex: {
                        bonus: "BONUS",
                        title: "External Story 09",
                        story: "Within the BioReviews workspace, customers can compare and assess different omics studies. They can map the linkage between genomics and proteomics experiments, integrate ribonucleic acid (RNA) sequences versus proteomics, or proteomic versus proteomic observations.",
                    },
                    display: {
                        viewStory: !1,
                        intLens: !1
                    }
                },
                




                
                
            }
        }
    }
  },

  mutations: {

    clearStoryView: function(e) {
      e.viewingStory = ""
    },
    updateStoryView: function(e, s) {
        e.viewingStory = s
    },
    pageIncrease: function(e) {
        e.currentPg++
    },
    pageDecrease: function(e) {
        e.currentPg--
    },
    pageReset: function(e) {
        e.currentPg = 0
    },
    toggleStoryView: function(e, t) {
        e.viewingStory = "";
        var o = e.products[t.prod].stories[t.story].display.viewStory;
        e.products[t.prod].stories[t.story].display.viewStory = !o,
        e.products[t.prod].stories[t.story].display.viewStory && (e.viewingStory = t.story)
    },
    lensPos: function(e, t) {
        e.lensPos = t
    },
    mousePos: function(e, t) {
      e.mousePos = t
    },
    toggleNav: function(e) {
      e.showNav = !e.showNav;
    },
    resetLensPostition: function(e) {
        e.lensPos = { x: 652, y: 521 }; //Reset lens position
        e.viewingStory = '';
        e.currentPg = 1;
    },
    toggleVideo: function(e) {
        e.displayVideo.show = !e.displayVideo.show;
    },
    updateVideo: function(e, t) {
        e.displayVideo.vid = t;
    }

  },

  actions: {},
  modules: {}
});
